<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md6></v-flex>
            <v-flex md6 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">SLEEP RESEARCH</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">睡眠研究</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">睡眠研究</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1308 ctk-container">
      <div class="ctk-page-title wow fadeInUp">睡眠研究</div>
        <v-tabs
          class="ctk-tabs"
          centered
          center-active
          hide-slider
          :show-arrows="false"
          v-model="tab"
        >
          <v-tab :ripple="false"><a href="/researchs/1">睡眠研究资讯</a></v-tab>
          <v-tab :ripple="false"><a href="/researchs/2">睡眠科普知识</a></v-tab>
        </v-tabs>
        <v-layout wrap v-if="news.data">
          <v-flex xs12 md3 v-for="item in news.data" :key="item.key">
            <v-card flat tile class="research-card">
              <a :href="'/research/' + item.id">
                <div class="research-img wowd fadeInUp"><img :src="item.img"/></div>
                <div class="research-title wowd fadeInUp" data-wow-delay="0.2s">{{item.title}}</div>
              </a>
            </v-card>
          </v-flex>
        </v-layout>
      <template v-if="totalPage > 1">
        <div class="text-center">
          <v-pagination v-model="page" :length="totalPage"></v-pagination>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 14,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' }
    ],
    news: [],
    totalPage: 1,
    types: { 1: '睡眠研究资讯', 2: '睡眠科普知识' },
    tab: 0,
    type: 1,
    page: 1
  }),
  created () {
    document.title = '睡眠研究-好睡眠'
    document.querySelector('#menu_research').classList.add('v-btn--active')
    if (this.$route.params.type === undefined) {
      this.type = 1
    } else {
      this.type = parseInt(this.$route.params.type)
    }
    if (this.$route.params.page === undefined) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.params.page)
    }
    this.tab = this.type - 1
    this.breadcrumbs.push({ text: this.types[this.type], disabled: true })
    this.getNews(this.type, this.page)
  },
  mounted () {
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        if (val.page !== 1) {
          this.$router.push({ name: 'researchs', params: { type: val.type, page: val.page } })
        } else {
          this.$router.push({ name: 'researchs', params: { type: val.type } })
        }
      },
      deep: true
    },
    '$route' (to, from) {
      document.querySelector('.ctk-top-bar').scrollIntoView(true)
      this.getNews(this.type, this.page)
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    },
    params () {
      const { type, page } = this
      return { type, page }
    }
  },
  methods: {
    getNews (type, page) {
      this.totalPage = 1
      this.https.get('researchs', { cid: type, page: page, pageSize: 8 }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
        }
      }).then(_ => {
        this.$wowd.init()
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/research.css';
</style>
